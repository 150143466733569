import { Trade } from '@divswap/router-sdk'
import { Currency, CurrencyAmount,Percent, TradeType } from '@divswap/sdk-core'
import { Trade as V3Trade } from '@divswap/v1-sdk'
import { Trade as V2Trade } from '@divswap/v2-sdk'
import { BigNumber } from '@ethersproject/bignumber'
import { formatEther, parseEther } from '@ethersproject/units'
import { Trans } from '@lingui/macro'
import { useWeb3React } from '@web3-react/core'
import { sendAnalyticsEvent } from 'components/AmplitudeAnalytics'
import { ElementName, Event, EventName, PageName, SectionName } from 'components/AmplitudeAnalytics/constants'
import { Trace } from 'components/AmplitudeAnalytics/Trace'
import { TraceEvent } from 'components/AmplitudeAnalytics/TraceEvent'
import {
  formatPercentInBasisPointsNumber,
  formatToDecimal,
  getDurationFromDateMilliseconds,
  getTokenAddress,
} from 'components/AmplitudeAnalytics/utils'
import { sendEvent } from 'components/analytics'
import { DarkCard, DarkCardExchange, LightCard } from 'components/Card'
import { CardBGImage, CardNoise, CardSection, DataCard } from 'components/earn/styled'
import PriceImpactWarning from 'components/Exchange/PriceImpactWarning'
import UnsupportedCurrencyFooter from 'components/Exchange/UnsupportedCurrencyFooter'
import { NetworkAlert } from 'components/NetworkAlert/NetworkAlert'
import { RowBetween, RowFixed } from 'components/Row'
import { MouseoverTooltip } from 'components/Tooltip'
import { isSupportedChain } from 'constants/chains'
import { RedesignVariant, useRedesignFlag } from 'featureFlags/flags/redesign'
import { useDivswapContract} from 'hooks/useContract'
import { useSwapCallback } from 'hooks/useSwapCallback'
import useTransactionDeadline from 'hooks/useTransactionDeadline'
import JSBI from 'jsbi'
import { Context, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { ReactNode } from 'react'
import { ArrowDown, ArrowUp } from 'react-feather'
import { useNavigate } from 'react-router-dom'
import { Text } from 'rebass'
import { useToggleWalletModal } from 'state/application/hooks'
import { InterfaceTrade } from 'state/routing/types'
import { TradeState } from 'state/routing/types'
import styled, { css, DefaultTheme, ThemeContext } from 'styled-components/macro'

import AddressInputPanel from '../../components/AddressInputPanel'
import { ButtonCopy, ButtonError, ButtonLight, ButtonRed } from '../../components/Button'
import { AutoColumn } from '../../components/Column'
import ExchangeHeader from '../../components/Exchange/ExchangeHeader'
import { ArrowWrapper, SwapCallbackError, Wrapper } from '../../components/Exchange/styleds'
import ExchangeInputPanel from '../../components/ExchangeInputPanel'
import { AutoRow } from '../../components/Row'
import { SwitchLocaleLink } from '../../components/SwitchLocaleLink'
import { useAllTokens } from '../../hooks/Tokens'
import { ApprovalState, useApprovalOptimizedTrade, useApproveCallbackFromTrade } from '../../hooks/useApproveCallback'
import useENSAddress from '../../hooks/useENSAddress'
import { useERC20PermitFromTrade, UseERC20PermitState } from '../../hooks/useERC20Permit'
import useIsArgentWallet from '../../hooks/useIsArgentWallet'
import { useIsSwapUnsupported } from '../../hooks/useIsSwapUnsupported'
import { useStablecoinValue } from '../../hooks/useStablecoinPrice'
import useWrapCallback, { WrapType } from '../../hooks/useWrapCallback'
import { Field } from '../../state/exchange/actions'
import {
  useDefaultsFromURLSearch,
  useDerivedSwapInfo,
  useSwapActionHandlers,
  useSwapState,
} from '../../state/exchange/hooks'
import { LinkStyledButton, ThemedText } from '../../theme'
import { calculateGasMargin } from '../../utils/calculateGasMargin'
import { computeFiatValuePriceImpact } from '../../utils/computeFiatValuePriceImpact'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { computeRealizedPriceImpact, warningSeverity } from '../../utils/prices'
import AppBodyExchange from '../AppBodyExchange'
import GetETHPricing from '../GetETHUSD/GetUSDETH'


const FullAddress = styled.span`
  @media only screen and (max-width: 420px) {
    display: none;
  }
`

const AlertWrapper = styled.div`
  max-width: 460px;
  width: 100%;
`
const ArrowContainer = styled.div`
  display: inline-block;
  margin-left: 6%;
`
const ArrowDownWrapper = styled.div`
  margin-top: -80%;
  margin-left: 24%;
`
const ArrowUpWrapper = styled.div`
  margin-left: 56%;
  margin-top: -18%;
`
const BottomWrapper = styled.div<{ redesignFlag: boolean }>`
  ${({ redesignFlag }) =>
    redesignFlag &&
    css`
      background-color: ${({ theme }) => theme.backgroundModule};
      border-radius: 12px;
      padding: 12px 16px 10px;
      color: ${({ theme }) => theme.textSecondary};
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
    `}
`
const TopInputWrapper = styled.div<{ redesignFlag: boolean }>`
  padding: ${({ redesignFlag }) => redesignFlag && '0px 12px'};
  visibility: ${({ redesignFlag }) => !redesignFlag && 'none'};
`
const BottomInputWrapper = styled.div<{ redesignFlag: boolean }>`
  padding: ${({ redesignFlag }) => redesignFlag && '8px 0px'};
`
//min-width controls the overall width of both all the panels.
const PageWrapper = styled.div`
  min-width: 800px;
  max-width: 1000px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    min-width: 680px;
    max-width: 680px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    min-width: 600px;
    max-width: 600px;
  `};

  @media only screen and (max-width: 620px) {
    min-width: 500px;
    max-width: 500px;
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    min-width: 340px;
    max-width: 340px;
  `};
`

const VoteCard = styled(DataCard)`
  background: radial-gradient(76.02% 75.41% at 1.84% 0%, rgba(39, 174, 96, .5) 0%, rgba(0, 0, 0, 0.7) 100%);
  overflow: hidden;
`

const ResponsiveRow = styled(RowBetween)`
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
    align-items: flex-start;
    row-gap: 16px;
    width: 100%:
  `};
`

// responsive text
// disable the warning because we don't use the end prop, we just want to filter it out
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Label = styled(({ end, ...props }) => <ThemedText.DeprecatedLabel {...props} />)<{ end?: boolean }>`
  display: flex;
  font-size: 16px;
  justify-content: ${({ end }) => (end ? 'flex-end' : 'flex-start')};
  align-items: center;
`

export function getIsValidSwapQuote(
  trade: InterfaceTrade<Currency, Currency, TradeType> | undefined,
  tradeState: TradeState,
  swapInputError?: ReactNode
): boolean {
  return !!swapInputError && !!trade && (tradeState === TradeState.VALID || tradeState === TradeState.SYNCING)
}

function largerPercentValue(a?: Percent, b?: Percent) {
  if (a && b) {
    return a.greaterThan(b) ? a : b
  } else if (a) {
    return a
  } else if (b) {
    return b
  }
  return undefined
}








// --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

  // function GetETHPrice() {
  //   // The address of the contract which will provide the price of ETH
  //   // const addr = '0x8A753747A1Fa494EC906cE90E9f37563A8AF630e';
  //   // We create an instance of the contract which we can interact with
  //   const priceFeed = useGetETHprice()
  //   // We get the data from the last round of the contract 
  //   const roundData = priceFeed?.latestRoundData()
  //   // Determine how many decimals the price feed has (10**decimals)
  //   const decimals = priceFeed?.decimals()
  //   // We convert the price to a number and return it
  //   return Number((roundData.answer.toString() / Math.pow(10, decimals)).toFixed(2))
  // }

// URL REFERAL GENERATION
// Your getCookie function here...

function getCookie(name: string) {
  const dc = document.cookie
  const prefix = name + '='
  let begin = dc.indexOf('; ' + prefix)

  if (begin === -1) {
    begin = dc.indexOf(prefix)
    if (begin !== 0) return null
  } else {
    begin += 2
    let end = document.cookie.indexOf(';', begin)
    if (end === -1) {
      end = dc.length
    }
    return decodeURI(dc.substring(begin + prefix.length, end))
  }
  return null
}


const currentUrl = window.location.href;
// console.log("THIS IS THE URL BEING SEARCHED: "+currentUrl);
const refId = currentUrl.match(/0x[0-9a-fA-F]{40}/)
// console.log("THIS IS THE REFID: " + refId)


if (refId !== null) {
  let expires = "";
  const date = new Date();
  date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000));
  expires = "; expires=" + date.toUTCString();
  document.cookie = `refid=${refId}` + expires
} else {
  const existingRefCookie = getCookie('refid')
  // let expires = "";
  // const date = new Date();
  // date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000));
  // expires = "; expires=" + date.toUTCString();

  if (existingRefCookie === null) {
    console.log('Ref cookie was null. Setting to default.')
    document.cookie = 'refid=0x0000000000000000000000000000000000000000' // Need to place this to the connected
    // document.cookie = 'refid=${account}' + expires
  } else {
    console.log("THIS IS THE REFID: " + refId)
  }
}

const formatSwapQuoteReceivedEventProperties = (
  trade: InterfaceTrade<Currency, Currency, TradeType>,
  fetchingSwapQuoteStartTime: Date | undefined
) => {
  return {
    token_in_symbol: trade.inputAmount.currency.symbol,
    token_out_symbol: trade.outputAmount.currency.symbol,
    token_in_address: getTokenAddress(trade.inputAmount.currency),
    token_out_address: getTokenAddress(trade.outputAmount.currency),
    price_impact_basis_points: trade ? formatPercentInBasisPointsNumber(computeRealizedPriceImpact(trade)) : undefined,
    estimated_network_fee_usd: trade.gasUseEstimateUSD ? formatToDecimal(trade.gasUseEstimateUSD, 2) : undefined,
    chain_id:
      trade.inputAmount.currency.chainId === trade.outputAmount.currency.chainId
        ? trade.inputAmount.currency.chainId
        : undefined,
    token_in_amount: formatToDecimal(trade.inputAmount, trade.inputAmount.currency.decimals),
    token_out_amount: formatToDecimal(trade.outputAmount, trade.outputAmount.currency.decimals),
    quote_latency_milliseconds: fetchingSwapQuoteStartTime
      ? getDurationFromDateMilliseconds(fetchingSwapQuoteStartTime)
      : undefined,
  }
}

// const fetchEthPrice = async () => {
//   try {
//     const result = await GetETHPricing();
//     console.log("THE ETH PRICE: " + result);
//   } catch (error) {
//     // Handle errors if any
//     console.error("THE ETH PRICE ERROR: " + error);
//   }
// };

export default function Swap() {
  const navigate = useNavigate()
  const redesignFlag = useRedesignFlag()
  const redesignFlagEnabled = redesignFlag === RedesignVariant.Enabled
  const { account, chainId, provider } = useWeb3React()
  const loadedUrlParams = useDefaultsFromURLSearch()
  const [newSwapQuoteNeedsLogging, setNewSwapQuoteNeedsLogging] = useState(true)
  const [fetchingSwapQuoteStartTime, setFetchingSwapQuoteStartTime] = useState<Date | undefined>()
  const [ethPrice, setEthPrice] = useState<number | string>()
  const [balOfTokenstoETH, setbalOfTokenstoETH] = useState<number | string>()
  const [balOfDividends, setbalOfDividends] = useState<number | string>()
  const [balOfReferrals, setbalOfReferrals] = useState<number | string>()
  const [tokenSupply, setTokenSupply] = useState<number | string>()
  const [calcTokensReceived, setCalcTokensReceived] = useState<number | string>()
  const [totalBalance, setTotalBalance] = useState<number | string>()
  const [calculatedOutput, setCalculatedOutput] = useState<number | string>("0")
  // const urlDisplay = "https://app.divswap.com/#/exchange?refid="+{account}.toString()

  // something is wrong with the eth pricing method when deploying specifically to app.divswap.com

  const ETHprice = GetETHPricing().then((result) => {
    setEthPrice(result)
    // console.log("THE ETH PRICE: " + result);
  }).catch((error) => {
    // console.error("THE ETH PRICE ERROR: " + error);
  })

  // GetETHPricing().then((result) => {
  //   // Handle the value here
  //   setEthPrice(result)
  //   console.log("THE ETH PRICE: " + result);
  // }).catch((error) => {
  //   // Handle errors if any
  //   const result = "—"
  //   setEthPrice(result)
  //   console.error("THE ETH PRICE ERROR: " + error);
  // })


// Import the following and uncomment the divswapContractWeb3 const to use the divswap contract. Note that provider is in the Web3Provider format for metamask. 

// import { Contract } from '@ethersproject/contracts'
// import  DIVSWAP_ABI from '../../abis/divswap_abi.json'
  // const tempSigner = provider?.getSigner()
  // const divswapContractWeb3 = new Contract("0xd1873c48bd902673f7ca4a24d3c0a0b7fd263a7f", DIVSWAP_ABI, tempSigner)

  const divswapContract = useDivswapContract()
  // import using "constants/addresses" next
  const ETHbalance = provider?.getBalance("0x846663929C73b75e37c4407Af5c1eCde9189277a").then((ETHbalance: BigNumber) => {
    setTotalBalance(formatEther(ETHbalance))
    // console.log("BALANCE OF CONTRACT ETH: ", Number(formatEther(ETHbalance))); // This should log the actual value
  })
  .catch((error: number) => {
    console.error("Error fetching balance of contract eth:", error);
  });


  const balanceOfAdr = divswapContract?.balanceOf(account)
  const balanceOfAdrETH = divswapContract?.calculateEthereumReceived(balanceOfAdr).then((balanceOfAdrETH: number) => {
    setbalOfTokenstoETH(formatEther(balanceOfAdrETH))
    // console.log("BALANCE OF TOKENS IN ETH: ", Number(formatEther(balanceOfAdrETH))); // This should log the actual value
  })
  .catch((error: number) => {
    // console.error("Error fetching balance of dividends:", error);
  });

  const balanceOfDividends = divswapContract?.dividendsOf(account).then((balanceOfDividends: number) => {
    setbalOfDividends(formatEther(balanceOfDividends))
    // console.log("BALANCE OF DIVIDENDS: ", Number(formatEther(balanceOfDividends))); // This should log the actual value
  })
  .catch((error: number) => {
    // console.error("Error fetching balance of dividends:", error);
  });

  const balanceOfReferrals = divswapContract?.referralBalance_(account).then((balanceOfReferrals: number) => {
    setbalOfReferrals(formatEther(balanceOfReferrals))
    // console.log("BALANCE OF REFERRALS: ", Number(formatEther(balanceOfReferrals))); // This should log the actual value
  })
  .catch((error: number) => {
    // console.error("Error fetching balance of dividends:", error);
  });

  const tokenSupplyOfContract = divswapContract?.tokenSupply_().then((tokenSupplyOfContract: number) => {
    setTokenSupply(formatEther(tokenSupplyOfContract))
    // console.log("TOKEN SUPPLY: ", Number(formatEther(tokenSupplyOfContract))); // This should log the actual value
  })
  .catch((error: number) => {
    // console.error("Error fetching balance of dividends:", error);
  });


  // const totalBalanceOfContract = provider.getBalance(divswapContract?.address).then((totalBalanceOfContract: number) => {
  //   setTotalBalance(formatEther(totalBalanceOfContract))
  //   console.log("BALANCE OF CONTRACT: ", Number(formatEther(totalBalanceOfContract))); // This should log the actual value
  // })
  // .catch((error: number) => {
  //   console.error("Error fetching balance of dividends:", error);
  // });
 
  const buyDiv = () => {
    
    // check if the contract is connected, or if there is no provider, or if there is no account. Should add !chainId to the if statement || !chainId
    if (!divswapContract || !provider || !account)  {
      console.log(divswapContract)
      console.log(provider)
      console.log(account)
      return

    }

    // console.log("refID: ", refId)
    // console.log("account: ", account)

    // prepare the transaction
    const txn = {
      to: divswapContract.address,
      data: divswapContract.interface.encodeFunctionData("buy", [refId?.toString() === "0x0000000000000000000000000000000000000000" || !refId ? account?.toString() : refId?.toString()]),
      // if the refId is already set to address(0) or if the refId is null, then set it to the current account, or then set it to the referrers account.
      value: parseEther((parsedAmounts[dependentField]?.toSignificant(12) ?? '0')?.toString())
      // value: divswapContract.buy(parseEther((parsedAmounts[dependentField]?.toSignificant(6) ?? '0')?.toString()))
    }

    provider
    .getSigner()
    .estimateGas(txn)
    .then((estimate) => {
      const newTxn = {
        ...txn,
        gasLimit: calculateGasMargin(estimate),
      }

      return provider.getSigner().sendTransaction(newTxn)  
    }).catch((error) => {
      console.error(error)
    })
  }

  const sellDiv = () => {
    
    // check if the contract is connected, or if there is no provider, or if there is no account. Should add !chainId to the if statement || !chainId
    if (!divswapContract || !provider || !account)  {
      console.log(divswapContract)
      console.log(provider)
      console.log(account)
      return

    }

    // prepare the transaction
    const txn = {
      to: divswapContract.address,
      data: divswapContract.interface.encodeFunctionData("sell", [parseEther((parsedAmounts[dependentField]?.toSignificant(12) ?? '0')?.toString())])
      // value: divswapContract.buy(parseEther((parsedAmounts[dependentField]?.toSignificant(6) ?? '0')?.toString()))
    }

    provider
    .getSigner()
    .estimateGas(txn)
    .then((estimate) => {
      const newTxn = {
        ...txn,
        gasLimit: calculateGasMargin(estimate),
      }

      return provider.getSigner().sendTransaction(newTxn)  
    }).catch((error) => {
      console.error(error)
    })
  }


  const reinvest = () => {
    
    // check if the contract is connected, or if there is no provider, or if there is no account. Should add !chainId to the if statement || !chainId
    if (!divswapContract || !provider || !account)  {
      console.log(divswapContract)
      console.log(provider)
      console.log(account)
      return

    }

    // prepare the transaction
    const txn = {
      to: divswapContract.address,
      data: divswapContract.interface.encodeFunctionData("reinvest")
    }

    provider
    .getSigner()
    .estimateGas(txn)
    .then((estimate) => {
      const newTxn = {
        ...txn,
        gasLimit: calculateGasMargin(estimate),
      }

      return provider.getSigner().sendTransaction(newTxn)  
    }).catch((error) => {
      console.error(error)
      // const manualGas = {
      //   ...txn,
      //   gasLimit: 1000000,
      // }
      // return provider.getSigner().sendTransaction(manualGas)  
    })
  }

  const withdraw = () => {
    
    // check if the contract is connected, or if there is no provider, or if there is no account. Should add !chainId to the if statement || !chainId
    if (!divswapContract || !provider || !account || !chainId)  {
      console.log(divswapContract)
      console.log(provider)
      console.log(account)
      return

    }

    // prepare the transaction
    const txn = {
      to: divswapContract.address,
      data: divswapContract.interface.encodeFunctionData("withdraw")
    }

    provider
    .getSigner()
    .estimateGas(txn)
    .then((estimate) => {
      const newTxn = {
        ...txn,
        gasLimit: calculateGasMargin(estimate),
      }

      return provider.getSigner().sendTransaction(newTxn)  
    }).catch((error) => {
      console.error(error)
    })
  }


  // token warning stuff
  // const [loadedInputCurrency, loadedOutputCurrency] = [
  //   useCurrency(loadedUrlParams?.[Field.INPUT]?.currencyId),
  //   useCurrency(loadedUrlParams?.[Field.OUTPUT]?.currencyId),
  // ]
  const [dismissTokenWarning, setDismissTokenWarning] = useState<boolean>(false)
  // const urlLoadedTokens: Token[] = useMemo(
  //   () => [loadedInputCurrency, loadedOutputCurrency]?.filter((c): c is Token => c?.isToken ?? false) ?? [],
  //   [loadedInputCurrency, loadedOutputCurrency]
  // )
  const handleConfirmTokenWarning = useCallback(() => {
    setDismissTokenWarning(true)
  }, [])

  // dismiss warning if all imported tokens are in active lists
  const defaultTokens = useAllTokens()
  // const importTokensNotInDefault = useMemo(
  //   () =>
  //     urlLoadedTokens &&
  //     urlLoadedTokens
  //       .filter((token: Token) => {
  //         return !Boolean(token.address in defaultTokens)
  //       })
  //       .filter((token: Token) => {
  //         // Any token addresses that are loaded from the shorthands map do not need to show the import URL
  //         const supported = supportedChainId(chainId)
  //         if (!supported) return true
  //         return !Object.keys(TOKEN_SHORTHANDS).some((shorthand) => {
  //           const shorthandTokenAddress = TOKEN_SHORTHANDS[shorthand][supported]
  //           return shorthandTokenAddress && shorthandTokenAddress === token.address
  //         })
  //       }),
  //   [chainId, defaultTokens, urlLoadedTokens]
  // )

  const theme = useContext(ThemeContext as Context<DefaultTheme>)

  // toggle wallet when disconnected
  const toggleWalletModal = useToggleWalletModal()

  // for expert mode
  // const [isExpertMode] = useExpertModeManager()

  // swap state
  const { independentField, typedValue, recipient } = useSwapState()
  const {
    trade: { state: tradeState, trade },
    allowedSlippage,
    currencyBalances,
    parsedAmount,
    currencies,
    inputError: swapInputError,
  } = useDerivedSwapInfo()

  const {
    wrapType,
    execute: onWrap,
    inputError: wrapInputError,
  } = useWrapCallback(currencies[Field.INPUT], currencies[Field.OUTPUT], typedValue)
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE
  const { address: recipientAddress } = useENSAddress(recipient)

  const parsedAmounts = useMemo(
    () =>
      currencies[Field.OUTPUT]?.symbol === "LMBO" // we cna probably just change showWrap to trade direction. If True, then the bottom parsedAmount will be modified to show the amount of tokens received. If False, then the bottom parsedAmount will be modified to show the amount of ethereum received.
        ? {
            [Field.INPUT]: parsedAmount,
            [Field.OUTPUT]: parsedAmount,
          }
        : {
            [Field.INPUT]: parsedAmount, // change this to only show wrapped amounts
            // [Field.OUTPUT]: parsedAmount?.multiply(JSBI.BigInt(123)),
            [Field.OUTPUT]: parsedAmount,
            
          },
    [parsedAmount]
  )

  // console.log("THIS IS THE OUTPUT CURRENCY: ", currencies[Field.OUTPUT]?.symbol)

  const [routeNotFound, routeIsLoading, routeIsSyncing] = useMemo(
    () => [!trade?.swaps, TradeState.LOADING === tradeState, TradeState.SYNCING === tradeState],
    [trade, tradeState]
  )

  // // let addone: CurrencyAmount<Token>
  // export interface mathematica {
  //     addone: CurrencyAmount<Currency>
  //   }

  // function sqrtBigInt(outputParsedAmount: CurrencyAmount<Currency> | undefined): CurrencyAmount<Currency> | undefined {
  //   // function sqrtBigInt(outputParsedAmount: Fraction ): Fraction {
      
  //     const DIV = chainId ? DIV_GOERLI : undefined
  //     console.log("THIS IS THE DIV: ", DIV)

  //     if (outputParsedAmount?.lessThan(0)){
  //       throw new Error('CANNOT SQRT NEGATIVE NUMBER')
  //     }
      
  //     console.log("THIS IS THE QUOTIENT: ", outputParsedAmount?.currency)

  //     if (outputParsedAmount?.equalTo(0) || outputParsedAmount?.equalTo(1)){
  //       return outputParsedAmount
  //     }

  //     // return CurrencyAmount.fromRawAmount(DIV,

  //     // const val: CurrencyAmount<Currency> = JSBI.BigInt
  //     // CurrencyAmount.fromRawAmount(outputParsedAmount?.currency, outputParsedAmount?.quotient)
  //     const guess = outputParsedAmount
    
  //     return guess;
  //   }
  

  // show price estimates based on wrap trade
  const inputValue = showWrap ? parsedAmount : trade?.inputAmount
  const outputValue = showWrap ? parsedAmount : trade?.outputAmount
  const fiatValueInput = useStablecoinValue(inputValue)
  const fiatValueOutput = useStablecoinValue(outputValue)
  const stablecoinPriceImpact = useMemo(
    () => (routeIsSyncing ? undefined : computeFiatValuePriceImpact(fiatValueInput, fiatValueOutput)),
    [fiatValueInput, fiatValueOutput, routeIsSyncing]
  )

  const { onSwitchTokens, onCurrencySelection, onUserInput, onChangeRecipient } = useSwapActionHandlers()
  const isValid = !swapInputError
  const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT
  


 
  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value)
    },
    [onUserInput]
  )

  const setInputZero = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, "0")
    },
    [onUserInput]
  )
  // console.log("THIS IS THE INPUT VALUE: ", calculatedOutput)

  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value)
    },
    [onUserInput]
  )

  // reset if they close warning without tokens in params
  const handleDismissTokenWarning = useCallback(() => {
    setDismissTokenWarning(true)
    navigate('/swap/')
  }, [navigate])

  // modal and loading
  const [{ showConfirm, tradeToConfirm, swapErrorMessage, attemptingTxn, txHash }, setSwapState] = useState<{
    showConfirm: boolean
    tradeToConfirm: Trade<Currency, Currency, TradeType> | undefined
    attemptingTxn: boolean
    swapErrorMessage: string | undefined
    txHash: string | undefined
  }>({
    showConfirm: false,
    tradeToConfirm: undefined,
    attemptingTxn: false,
    swapErrorMessage: undefined,
    txHash: undefined,
  })

  const formattedAmounts = useMemo(
    () => ({
      [independentField]: typedValue,
      [dependentField]: currencies[Field.OUTPUT]?.symbol === "LMBO"
      ? (
        (
          ((((Math.sqrt((0.0000001**2) + (2*0.00000001*(Number(parsedAmounts[dependentField]?.toSignificant(18)) * 0.9)) + ((0.00000001 ** 2)*(Number(tokenSupply) ** 2)) + (2*0.00000001*0.0000001*Number(tokenSupply))) - 0.0000001) / 0.00000001) - Number(tokenSupply))).toFixed(10)
        )
        .toString()
         || '0')
      // (Math.sqrt((Number(parsedAmounts[dependentField]?.toSignificant(6)) * Number(123))).toString() || '0')
      : 
      (
        Math.max((
          (
            (
              (0.0000001 + (0.00000001*(Number(tokenSupply))))*(Number(parsedAmounts[dependentField]?.toSignificant(18)))
            ) - (0.00000001*((Number(parsedAmounts[dependentField]?.toSignificant(18)))**2))/2
          )*0.9
        ), 0).toString() || '0'
      ),
    }),
    [dependentField, independentField, parsedAmounts, showWrap, typedValue]
  )

 
  

  // console.log("THIS IS THE PARSED AMOUNT: ", (parsedAmounts[dependentField]?.toSignificant(6))?.toString())




  const userHasSpecifiedInputOutput = Boolean(
    currencies[Field.INPUT] && currencies[Field.OUTPUT] && parsedAmounts[independentField]?.greaterThan(JSBI.BigInt(0))
  )

  const approvalOptimizedTrade = useApprovalOptimizedTrade(trade, allowedSlippage)
  const approvalOptimizedTradeString =
    approvalOptimizedTrade instanceof V2Trade
      ? 'V2SwapRouter'
      : approvalOptimizedTrade instanceof V3Trade
      ? 'V3SwapRouter'
      : 'SwapRouter'

  // check whether the user has approved the router on the input token
  const [approvalState, approveCallback] = useApproveCallbackFromTrade(approvalOptimizedTrade, allowedSlippage)
  const transactionDeadline = useTransactionDeadline()
  const {
    state: signatureState,
    signatureData,
    gatherPermitSignature,
  } = useERC20PermitFromTrade(approvalOptimizedTrade, allowedSlippage, transactionDeadline)

  const handleApprove = useCallback(async () => {
    if (signatureState === UseERC20PermitState.NOT_SIGNED && gatherPermitSignature) {
      try {
        await gatherPermitSignature()
      } catch (error) {
        // try to approve if gatherPermitSignature failed for any reason other than the user rejecting it
        if (error?.code !== 4001) {
          await approveCallback()
        }
      }
    } else {
      await approveCallback()

      sendEvent({
        category: 'Swap',
        action: 'Approve',
        label: [approvalOptimizedTradeString, approvalOptimizedTrade?.inputAmount?.currency.symbol].join('/'),
      })
    }
  }, [
    signatureState,
    gatherPermitSignature,
    approveCallback,
    approvalOptimizedTradeString,
    approvalOptimizedTrade?.inputAmount?.currency.symbol,
  ])

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false)

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approvalState === ApprovalState.PENDING) {
      setApprovalSubmitted(true)
    }
  }, [approvalState, approvalSubmitted])

  const maxInputAmount: CurrencyAmount<Currency> | undefined = useMemo(
    () => maxAmountSpend(currencyBalances[Field.INPUT]),
    [currencyBalances]
  )
  const showMaxButton = Boolean(maxInputAmount?.greaterThan(0) && !parsedAmounts[Field.INPUT]?.equalTo(maxInputAmount))

  // the callback to execute the swap
  const { callback: swapCallback, error: swapCallbackError } = useSwapCallback(
    approvalOptimizedTrade,
    allowedSlippage,
    recipient,
    signatureData
  )

  // errors
  const [showInverted, setShowInverted] = useState<boolean>(false)
  const [swapQuoteReceivedDate, setSwapQuoteReceivedDate] = useState<Date | undefined>()

  // warnings on the greater of fiat value price impact and execution price impact
  const { priceImpactSeverity, largerPriceImpact } = useMemo(() => {
    const marketPriceImpact = trade?.priceImpact ? computeRealizedPriceImpact(trade) : undefined
    const largerPriceImpact = largerPercentValue(marketPriceImpact, stablecoinPriceImpact)
    return { priceImpactSeverity: warningSeverity(largerPriceImpact), largerPriceImpact }
  }, [stablecoinPriceImpact, trade])

  const isArgentWallet = useIsArgentWallet()

  // show approve flow when: no error on inputs, not approved or pending, or approved in current session
  // never show if price impact is above threshold in non expert mode
  const showApproveFlow =
    !isArgentWallet &&
    !swapInputError &&
    (approvalState === ApprovalState.NOT_APPROVED ||
      approvalState === ApprovalState.PENDING ||
      (approvalSubmitted && approvalState === ApprovalState.APPROVED))
  // && !(priceImpactSeverity > 3 && !isExpertMode)

  const handleConfirmDismiss = useCallback(() => {
    setSwapState({ showConfirm: false, tradeToConfirm, attemptingTxn, swapErrorMessage, txHash })
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.INPUT, '')
    }
  }, [attemptingTxn, onUserInput, swapErrorMessage, tradeToConfirm, txHash])

  const handleAcceptChanges = useCallback(() => {
    setSwapState({ tradeToConfirm: trade, swapErrorMessage, txHash, attemptingTxn, showConfirm })
  }, [attemptingTxn, showConfirm, swapErrorMessage, trade, txHash])

  // const handleInputSelect = useCallback(
  //   (inputCurrency: Currency) => {
  //     setApprovalSubmitted(false) // reset 2 step UI for approvals
  //     onCurrencySelection(Field.INPUT, inputCurrency)
  //   },
  //   [onCurrencySelection]
  // )

  const handleMaxInput = useCallback(() => {
    maxInputAmount && onUserInput(Field.INPUT, maxInputAmount.toExact())
    sendEvent({
      category: 'Swap',
      action: 'Max',
    })
  }, [maxInputAmount, onUserInput])

  const handleOutputSelect = useCallback(
    (outputCurrency: Currency) => onCurrencySelection(Field.OUTPUT, outputCurrency),
    [onCurrencySelection]
  )

  const swapIsUnsupported = useIsSwapUnsupported(currencies[Field.INPUT], currencies[Field.OUTPUT])

  const priceImpactTooHigh = priceImpactSeverity > 3
  const showPriceImpactWarning = largerPriceImpact && priceImpactSeverity > 3

  // Handle time based logging events and event properties.
  useEffect(() => {
    const now = new Date()
    // If a trade exists, and we need to log the receipt of this new swap quote:
    if (newSwapQuoteNeedsLogging && !!trade) {
      // Set the current datetime as the time of receipt of latest swap quote.
      setSwapQuoteReceivedDate(now)
      // Log swap quote.
      sendAnalyticsEvent(
        EventName.SWAP_QUOTE_RECEIVED,
        formatSwapQuoteReceivedEventProperties(trade, fetchingSwapQuoteStartTime)
      )
      // Latest swap quote has just been logged, so we don't need to log the current trade anymore
      // unless user inputs change again and a new trade is in the process of being generated.
      setNewSwapQuoteNeedsLogging(false)
      // New quote is not being fetched, so set start time of quote fetch to undefined.
      setFetchingSwapQuoteStartTime(undefined)
    }
    // If another swap quote is being loaded based on changed user inputs:
    if (routeIsLoading) {
      setNewSwapQuoteNeedsLogging(true)
      if (!fetchingSwapQuoteStartTime) setFetchingSwapQuoteStartTime(now)
    }
  }, [
    newSwapQuoteNeedsLogging,
    routeIsSyncing,
    routeIsLoading,
    fetchingSwapQuoteStartTime,
    trade,
    setSwapQuoteReceivedDate,
  ])

  const approveTokenButtonDisabled =
    approvalState !== ApprovalState.NOT_APPROVED || approvalSubmitted || signatureState === UseERC20PermitState.SIGNED


  const MobileWrapper = styled(AutoColumn)`
    ${({ theme }) => theme.mediaWidth.upToExtraSmall`
      display: inline-flex;
    `};
  `

  return (
    <Trace page={PageName.EXCHANGE_PAGE} shouldLogImpression>
      <>
        <PageWrapper>
          <ResponsiveRow align="flex-start">
            <AppBodyExchange>
              <ExchangeHeader allowedSlippage={allowedSlippage} />
              <Wrapper id="exchange-page">
                <AutoColumn gap={'0px'}>
                  <div style={{ display: 'relative' }}>
                    <TopInputWrapper redesignFlag={redesignFlagEnabled}>
                      <Trace section={SectionName.CURRENCY_INPUT_PANEL}>
                        <ExchangeInputPanel
                          label={
                            currencies[Field.OUTPUT]?.symbol === "LMBO" ? (
                              <Trans>You Send</Trans>
                            ) : (
                              <Trans>You Sell</Trans>
                            )
                          }
                          // value={formattedAmounts[Field.INPUT]}
                          value={(formattedAmounts[Field.INPUT] && !isNaN(Number(formattedAmounts[Field.INPUT])))
                            ? formattedAmounts[Field.INPUT]
                            : ""}
                          showMaxButton={showMaxButton}
                          currency={currencies[Field.INPUT] ?? null}
                          onUserInput={handleTypeInput}
                          onMax={handleMaxInput}
                          fiatValue={fiatValueInput ?? undefined}
                          onCurrencySelect={undefined} // handleInputSelect ==> undefined
                          otherCurrency={currencies[Field.OUTPUT]}
                          showCommonBases={true}
                          id={SectionName.CURRENCY_INPUT_PANEL}
                          loading={independentField === Field.OUTPUT && routeIsSyncing}
                        />
                      </Trace>
                    </TopInputWrapper>

                          {/* REVERSED */}

                    <ArrowWrapper clickable={isSupportedChain(chainId)} redesignFlag={redesignFlagEnabled}>
                      <TraceEvent
                        events={[Event.onClick]}
                        name={EventName.SWAP_TOKENS_REVERSED} // check on how to toggle a boolean here to mint or sell divswap.
                        element={ElementName.SWAP_TOKENS_REVERSE_ARROW_BUTTON}
                      >
                        {redesignFlagEnabled ? (
                          <ArrowContainer
                            onClick={() => {
                              setInputZero("0")
                              setApprovalSubmitted(false) // reset 2 step UI for approvals
                              onSwitchTokens()
                            }}
                            color={theme.textPrimary}
                          >
                            <ArrowUpWrapper>
                              <ArrowUp size="12" stroke-width="3" />
                            </ArrowUpWrapper>
                            <ArrowDownWrapper>
                              <ArrowDown size="12" stroke-width="3" />
                            </ArrowDownWrapper>
                          </ArrowContainer>
                        ) : (
                          <ArrowDown
                            size="16"
                            onClick={() => {
                              setApprovalSubmitted(false) // reset 2 step UI for approvals
                              onSwitchTokens()
                              
                            }}
                            color={
                              currencies[Field.INPUT] && currencies[Field.OUTPUT]
                                ? theme.deprecated_text1
                                : theme.deprecated_text3
                            }
                          />
                        )}
                      </TraceEvent>
                    </ArrowWrapper>
                  </div>
                  <BottomWrapper redesignFlag={redesignFlagEnabled}>
                    {redesignFlagEnabled}
                    <AutoColumn gap={redesignFlagEnabled ? '0px' : '8px'}>
                      <BottomInputWrapper redesignFlag={redesignFlagEnabled}>
                        <Trace section={SectionName.CURRENCY_OUTPUT_PANEL}>
                          <ExchangeInputPanel
                            // value={"~" + (Number(calculatedOutput)*123) ?? "0"}
                            value={(formattedAmounts[Field.OUTPUT] && !isNaN(Number(formattedAmounts[Field.OUTPUT])))
                              ? formattedAmounts[Field.OUTPUT]
                              : ""}
                            onUserInput={handleTypeOutput}
                            label={
                              independentField === Field.INPUT && !showWrap ? (
                                <Trans>You Receive</Trans>
                              ) : (
                                <Trans>You Receive</Trans>
                              )
                            }
                            showMaxButton={false}
                            hideBalance={false}
                            fiatValue={fiatValueOutput ?? undefined}
                            priceImpact={stablecoinPriceImpact}
                            currency={currencies[Field.OUTPUT] ?? null}
                            onCurrencySelect={undefined} // handleOutputSelect ==> undefined
                            otherCurrency={currencies[Field.INPUT]}
                            showCommonBases={true}
                            id={SectionName.CURRENCY_OUTPUT_PANEL}
                            loading={independentField === Field.INPUT && routeIsSyncing}
                            locked={true}
                          />
                        </Trace>

                        {recipient !== null && !showWrap ? (
                          <>
                            <AutoRow justify="space-between" style={{ padding: '0 1rem' }}>
                              <ArrowWrapper clickable={false} redesignFlag={redesignFlagEnabled}>
                                <ArrowDown size="16" color={theme.deprecated_text2} />
                              </ArrowWrapper>
                              <LinkStyledButton id="remove-recipient-button" onClick={() => onChangeRecipient(null)}>
                                <Trans>- Remove recipient</Trans>
                              </LinkStyledButton>
                            </AutoRow>
                            <AddressInputPanel id="recipient" value={recipient} onChange={onChangeRecipient} />
                          </>
                        ) : null}

                        {/* THIS IS THE SWAP DROPDOWN. IT APPEARS WHEN FILLING OUT THE INPUT.  */}

                        {/* {!showWrap && userHasSpecifiedInputOutput && (trade || routeIsLoading || routeIsSyncing) && (
                          <SwapDetailsDropdown
                            trade={trade}
                            syncing={routeIsSyncing}
                            loading={routeIsLoading}
                            showInverted={showInverted}
                            setShowInverted={setShowInverted}
                            allowedSlippage={allowedSlippage}
                          />
                        )} */}

                        {showPriceImpactWarning && <PriceImpactWarning priceImpact={largerPriceImpact} />}
                      </BottomInputWrapper>
                      <div>
                        {/* If there is no account (string is empty), present the wallet connect modal to connect the wallet. Then, instantiate account with the account address. */}
                        {!account ? (
                          <TraceEvent
                            events={[Event.onClick]}
                            name={EventName.CONNECT_WALLET_BUTTON_CLICKED}
                            properties={{ received_swap_quote: getIsValidSwapQuote(trade, tradeState, swapInputError) }}
                            element={ElementName.CONNECT_WALLET_BUTTON}
                          >
                            <ButtonLight onClick={toggleWalletModal} redesignFlag={redesignFlagEnabled}>
                              <Trans>Connect Wallet</Trans>
                            </ButtonLight>
                          </TraceEvent>
                        ) :
                        (
                          <ButtonError
                            onClick={currencies[Field.OUTPUT]?.symbol === "LMBO" ? buyDiv : sellDiv}
                            id="swap-button"
                          >
                            <Text fontSize={20} fontWeight={500}>
                              {<Trans>Transact</Trans>}
                            </Text>
                          </ButtonError>
                        )}
                        {swapErrorMessage ? <SwapCallbackError error={swapErrorMessage} /> : null}
                      </div>
                    </AutoColumn>
                  </BottomWrapper>
                </AutoColumn>
              </Wrapper>
            </AppBodyExchange>

            {/* REWARDS AND ACTIONS BOXES */}
            {/* Adds a spacing from between the two columns */}
            <div style={{ marginRight: 12 }}></div>

            <AutoColumn gap="md" style={{ width: '100%', height: '100%' }}>
              {/* This div adds spacing from the top of the rewards and actions columns since AppBodyExchange has "margin-top=1rem" which adds a 1rem spacing */}
              {/* <div margin-top="12px"></div>
              <div margin-top="12px"></div> */}

              <DarkCardExchange border="1px solid rgba(254, 178, 57, 0.5)">
                <AutoColumn gap="md" style={{ width: '100%' }}>
                  <AutoColumn gap="md">
                    <Label>
                      <Trans>Balance</Trans>
                    </Label>
                    <ThemedText.DeprecatedLargeHeader
                              color={theme.textPrimary}
                              fontSize="36px"
                              fontWeight={500}
                    >
                      <Text>
                      ${
                        isNaN(Number(ethPrice)) ||
                        isNaN(Number(balOfDividends)) ||
                        isNaN(Number(balOfReferrals)) ||
                        isNaN(Number(balOfTokenstoETH)) ?
                          "-" :
                          (Number(ethPrice) * (Number(balOfDividends) + Number(balOfReferrals) + Number(balOfTokenstoETH))).toFixed(2)
                      }
                        {/* ${
                        (Number(ethPrice) 
                        * 
                        Number(
                          (
                        Number(balOfDividends) + 
                        Number(balOfReferrals) + 
                        Number(balOfTokenstoETH) 
                          )
                              )
                        ).toFixed(2)
                        } */}
                      </Text>

                    </ThemedText.DeprecatedLargeHeader>
                  </AutoColumn>

                  {/* THIS PADDING CONTROLS THE HEIGHT OF THE GREY BOX IN REWARDS */}
                  <LightCard padding="14px 16px"> 
                    <AutoColumn gap="md">
                      {/* THE TWO ROWBETWEENS CAN BE REMOVED.*/}

                      <RowBetween>
                        {/* <LinkedCurrency chainId={chainId} currency={currencyQuote} /> */}
                        <RowFixed>

                          <MouseoverTooltip
                            text={
                              <Trans>
                                The total amount of ETH deposited in the Lambos pool. 
                              </Trans>
                            }
                            disableHover={false}
                          >
                          <ThemedText.DeprecatedMain color={theme.deprecated_text1} fontSize={14}>
                            <Trans>Total Staked</Trans>
                          </ThemedText.DeprecatedMain>
                          </MouseoverTooltip>
                          


                        </RowFixed>
                        <ThemedText.DeprecatedBlack textAlign="right" fontSize={14}>
                          <Trans>{!isNaN(Number(totalBalance)) ? (Number(totalBalance).toFixed(5) ?? 0) : 0} ETH</Trans>
                        </ThemedText.DeprecatedBlack>
                      </RowBetween>


                      <RowBetween>
                        {/* <LinkedCurrency chainId={chainId} currency={currencyBase} /> */}
                        <RowFixed>
                          <MouseoverTooltip
                            text={
                              <Trans>
                                The amount of ETH you earned from dividends.
                              </Trans>
                            }
                            disableHover={false}
                          >
                          <ThemedText.DeprecatedMain color={theme.deprecated_text1} fontSize={14}>
                            <Trans>Dividend Rewards</Trans>
                          </ThemedText.DeprecatedMain>
                          </MouseoverTooltip>
                        </RowFixed>

                        <ThemedText.DeprecatedBlack textAlign="right" fontSize={14}>
                          <Trans>{(Number(balOfDividends ?? 0)).toFixed(2)} ETH</Trans>
                        </ThemedText.DeprecatedBlack>
                      </RowBetween>
                      
                      <RowBetween>
                        {/* <LinkedCurrency chainId={chainId} currency={currencyBase} /> */}
                        <RowFixed>
                          <MouseoverTooltip
                            text={
                              <Trans>
                                The amount of ETH you earned from referrals. 
                              </Trans>
                            }
                            disableHover={false}
                          >
                          <ThemedText.DeprecatedMain color={theme.deprecated_text1} fontSize={14}>
                            <Trans>Referral Rewards</Trans>
                          </ThemedText.DeprecatedMain>
                          </MouseoverTooltip>
                        </RowFixed>

                        <ThemedText.DeprecatedBlack textAlign="right" fontSize={14}>
                          <Trans>{(Number(balOfReferrals ?? 0)).toFixed(2)} ETH</Trans>
                        </ThemedText.DeprecatedBlack>
                      </RowBetween>

                      <RowBetween>
                        {/* <LinkedCurrency chainId={chainId} currency={currencyBase} /> */}
                        <RowFixed>

                          <MouseoverTooltip
                            text={
                              <Trans>
                                Sell your LMBO tokens to be able to reinvest or withdraw your ETH.
                              </Trans>
                            }
                            disableHover={false}
                          >
                          <ThemedText.DeprecatedMain color={theme.deprecated_text1} fontSize={14}>
                            <Trans>Withdrawable ETH</Trans>
                          </ThemedText.DeprecatedMain>
                          </MouseoverTooltip>
                        </RowFixed>

                        <ThemedText.DeprecatedBlack textAlign="right" fontSize={14}>
                          <Trans>{(Number(balOfDividends ?? 0) + Number(balOfReferrals ?? 0)).toFixed(2)} ETH</Trans>
                        </ThemedText.DeprecatedBlack>
                      </RowBetween>

                    </AutoColumn>
                  </LightCard>
                </AutoColumn>
              </DarkCardExchange>

              {/* <div style={{ marginBottom: -90 }}></div> */}

              <DarkCard border="1px solid rgba(254, 178, 57, 0.5)">
                <AutoColumn gap="md" style={{ width: '100%' }}>
                  <AutoColumn gap="md">
                    <RowBetween style={{ alignItems: 'flex-start' }}>
                      <AutoColumn gap="md">
                        <Label>
                          <Trans>Actions</Trans>
                        </Label>
                        {/* {fiatValueOfFees?.greaterThan(new Fraction(1, 100)) ? (
                          <ThemedText.DeprecatedLargeHeader
                            color={theme.deprecated_green1}
                            fontSize="36px"
                            fontWeight={500}
                          >
                            <Trans>${fiatValueOfFees.toFixed(2, { groupSeparator: ',' })}</Trans>
                          </ThemedText.DeprecatedLargeHeader>
                        ) : (
                          <ThemedText.DeprecatedLargeHeader
                            color={theme.deprecated_text1}
                            fontSize="36px"
                            fontWeight={500}
                          >
                            <Trans>$-</Trans>
                          </ThemedText.DeprecatedLargeHeader>
                        )} */}
                      </AutoColumn>
                    </RowBetween>
                  </AutoColumn>


                  <RowBetween>
                    <ButtonLight
                      onClick={reinvest}
                      padding="14px 14px"
                      redesignFlag={redesignFlagEnabled}
                      marginRight="12px"
                    >
                      <Trans>Reinvest</Trans>
                    </ButtonLight>
                    {/* BUTTON AREAS FOR REINVEST AND WITHDRAW */}
                    <ButtonRed
                      onClick={withdraw}
                      padding="14px 14px"
                      redesignFlag={redesignFlagEnabled}
                      marginRight="6px"
                    >
                      <Trans>Withdraw</Trans>
                    </ButtonRed>
                  </RowBetween>
                  {/* {showCollectAsWeth && (
                    <AutoColumn gap="md">
                      <RowBetween>
                        <ThemedText.DeprecatedMain>
                          <Trans>Collect as {nativeWrappedSymbol}</Trans>
                        </ThemedText.DeprecatedMain>
                        <Toggle
                          id="receive-as-weth"
                          isActive={receiveWETH}
                          toggle={() => setReceiveWETH((receiveWETH) => !receiveWETH)}
                        />
                      </RowBetween>
                    </AutoColumn>
                  )} */}
                </AutoColumn>
              </DarkCard>
            </AutoColumn>
          </ResponsiveRow>

          {/* REFERRAL LINK */}
          <div style={{ marginTop: 12 }}></div>

          <DarkCard border="1px solid rgba(254, 178, 57, 0.5)">
            <AutoColumn gap="md">
              <RowBetween>
                <RowFixed>
                  <Label display="flex" style={{ marginRight: '12px' }}>
                    <Trans>Your Referral Link</Trans>
                  </Label>
                  {/* <HideExtraSmall>
                    <>
                      <RangeBadge removed={removed} inRange={inRange} />
                      <span style={{ width: '8px' }} />
                    </>
                  </HideExtraSmall> */}
                </RowFixed>
                {/* <RowFixed>
                  {currencyBase && currencyQuote && (
                    <RateToggle
                      currencyA={currencyBase}
                      currencyB={currencyQuote}
                      handleRateToggle={() => setManuallyInverted(!manuallyInverted)}
                    />
                  )}
                </RowFixed> */}
              </RowBetween>

              {/* REFERRAL LINK GENERATION AND COPY BUTTON TO CLIPBOARD */}
              {/* CONSIDER COOKIE INJECT AND READ IN THIS? */}

              <RowBetween>
                <LightCard padding="9px" width="100%">
                    <AutoRow gap="9px">                  
                      <ThemedText.DeprecatedSmall color={theme.deprecated_text1}>
                      https://lambos.org/#/exchange?refid={account}
                      </ThemedText.DeprecatedSmall>
                    </AutoRow>
                </LightCard>

                <ButtonCopy
                  onClick={() => {
                  if (account) {
                    const textToCopy = `https://lambos.org/#/exchange?refid=${account}`;
                    navigator.clipboard.writeText(textToCopy)
                      .then(() => console.log('Copied referral link to clipboard'))
                      .catch((err) => console.error('Error copying text to clipboard', err));
                  }
                }}
                  padding="8px 8px"
                  redesignFlag={redesignFlagEnabled}
                  marginLeft="6px"
                  width="13%"
                >
                  <div>Copy Link</div>
                </ButtonCopy>
              </RowBetween>

              {/* <CurrentPriceCard
                inverted={inverted}
                pool={pool}
                currencyQuote={currencyQuote}
                currencyBase={currencyBase}
              /> */}
            </AutoColumn>
          </DarkCard>

          <div style={{ marginTop: 12 }}></div>

          <VoteCard>
            <CardBGImage />
            <CardNoise />
            <CardSection>
              <AutoColumn gap="md">
                <RowBetween>
                  <ThemedText.DeprecatedWhite fontWeight={600}>
                    <Trans>What is the Lambos Protocol</Trans>
                  </ThemedText.DeprecatedWhite>
                </RowBetween>
                <RowBetween>
                  <ThemedText.DeprecatedWhite fontSize={14}>
                    <Trans>
                      Lambos introduces a unique feature for Ethereum staking: Each time users stake or unstake ETH, a 10% tax is applied, and the resulting fees are distributed among all token holders in the protocol.
                      <br />
                      <br />
                      Currently, you can withdraw your ETH and dividends whenever you choose. Unlike liquid staking derivatives with a 1:1 ETH backing, your staking rewards will increase exponentially based on the number of LMB tokens you possess.  LambosV2 is in development to automatically stake all ETH and generate staking yield. After the upgrade is finalized, you&apos;ll earn lETH in proportion to your LMB token holdings.
                    </Trans>
                  </ThemedText.DeprecatedWhite>
                </RowBetween>

                <RowBetween>
                  <ThemedText.DeprecatedWhite fontWeight={600}>
                    <Trans>Referral rewards</Trans>
                  </ThemedText.DeprecatedWhite>
                </RowBetween>
                <RowBetween>
                  <ThemedText.DeprecatedWhite fontSize={14}>
                    <Trans>
                      Holding or buying at least 100 tokens will allow you to receive referral rewards and a 33% discount to token purchases. Whenever someone visits the site via your referral link, they have your wallet address saved in a cookie.
                      
                      When purchasing with your referral link, you instantly receive 33% of the buy-in fees that would otherwise be distributed to token holders. 
                    </Trans>

                  </ThemedText.DeprecatedWhite>
                </RowBetween>

                <RowBetween>
                  <ThemedText.DeprecatedWhite fontWeight={600}>
                    <Trans>What&apos;s in it for me</Trans>
                  </ThemedText.DeprecatedWhite>
                </RowBetween>
                <RowBetween>
                  <ThemedText.DeprecatedWhite fontSize={14}>
                    <Trans>
                      The LMBO token price follows a bonding curve: As a staking token holder, you continuously earn
                      &quot;free&quot; Ethereum from each taxed buy and sell transaction, determined by the amount of
                      LMBO tokens you hold.
                      <br />
                      <br /> 
                    </Trans>
                  </ThemedText.DeprecatedWhite>
                </RowBetween>

              </AutoColumn>
            </CardSection>
            <CardBGImage />
            <CardNoise />
          </VoteCard>
        </PageWrapper>
        <AlertWrapper>
          <NetworkAlert />
        </AlertWrapper>
        <SwitchLocaleLink />
        {!swapIsUnsupported ? null : (
          <UnsupportedCurrencyFooter
            show={swapIsUnsupported}
            currencies={[currencies[Field.INPUT], currencies[Field.OUTPUT]]}
          />
        )}
      </>
    </Trace>
  )
}
