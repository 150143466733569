import { Percent } from '@divswap/sdk-core'
import { Trans } from '@lingui/macro'
import styled from 'styled-components/macro'

import { ThemedText } from '../../theme'
import { RowBetween, RowFixed } from '../Row'

const StyledExchangeHeader = styled.div`
  padding: 1rem 1.25rem 0.5rem 1.25rem;
  width: 100%;
  color: ${({ theme }) => theme.deprecated_text2};
`

export default function ExchangeHeader({ allowedSlippage }: { allowedSlippage: Percent }) {
  return (
    <StyledExchangeHeader>
      <RowBetween>
        <RowFixed>
          <ThemedText.DeprecatedBlack fontWeight={500} fontSize={16} style={{ marginRight: '8px' }}>
            <Trans>Stake ETH</Trans>
          </ThemedText.DeprecatedBlack>
        </RowFixed>
        <RowFixed>{/* <SettingsTab placeholderSlippage={allowedSlippage} /> */}</RowFixed>
      </RowBetween>
    </StyledExchangeHeader>
  )
}
