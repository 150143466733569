import { useGetETHprice } from 'hooks/useContract'

export default async function GetETHPricing() {
  // The address of the contract which will provide the price of ETH
  // const addr = '0x8A753747A1Fa494EC906cE90E9f37563A8AF630e';
  // We create an instance of the contract which we can interact with
  const priceFeed = useGetETHprice()
  // We get the data from the last round of the contract
  const roundData = await priceFeed?.latestRoundData()
  // Determine how many decimals the price feed has (10**decimals)
  const decimals = await priceFeed?.decimals()
  // We convert the price to a number and return it
  return Number((roundData.answer / Math.pow(10, decimals)).toFixed(2))
}
